/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Interface
import { GalleryProps } from 'components/flex/Gallery/GalleryShell'
import ImageLightBox from 'components/elements/Misc/ImageLightbox'

const BigImage = styled(Plaatjie)`
  @media (min-width: 992px) {
    height: 780px;
  }
  @media (max-width: 991px) {
    height: 100%;
  }
`

const Image = styled(Plaatjie)`
  @media (min-width: 992px) {
    height: 426px;
  }
  @media (max-width: 991px) {
    height: 100%;
  }
`

const GalleryDefault: React.FC<GalleryProps> = ({ fields }) => {
  const imageCount = fields.gallery?.length || 0
  const { gallery } = fields

  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <section className="mb-5 pb-md-5">
      <div className="container">
        <div className="row">
          {imageCount < 2 && (
            <button type="button" onClick={() => setIsModalOpen(true)}>
              <BigImage image={gallery![0]} alt="" />
            </button>
          )}
          {imageCount === 2 && (
            <>
              {gallery?.map((image, index) => (
                <div className="col-6" key={index}>
                  <Image key={index} image={image} alt="" />
                </div>
              ))}
            </>
          )}
          {imageCount > 2 && (
            <>
              {gallery?.map((image, index) => (
                <>
                  {index !== imageCount - 1 && (
                    <button
                      type="button"
                      className="col-6 mb-4"
                      onClick={() => setIsModalOpen(true)}
                    >
                      <Image key={index} image={image} alt="" />
                    </button>
                  )}
                  {index === imageCount - 1 && (
                    <button
                      type="button"
                      className="col-12"
                      onClick={() => setIsModalOpen(true)}
                    >
                      <BigImage key={index} image={image} alt="" />
                    </button>
                  )}
                </>
              ))}
            </>
          )}
        </div>
      </div>

      {isModalOpen && <ImageLightBox fields={gallery} />}
    </section>
  )
}

export default GalleryDefault
