/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import SimpleReactLightbox, {
  SRLWrapper,
  useLightbox,
} from 'simple-react-lightbox'
import Plaatjie from '@ubo/plaatjie'

// Swiper
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper/core'

// Images
import SwiperArrow from 'img/chevron.svg'

// Interface
import { GalleryProps } from 'components/flex/Gallery/GalleryShell'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'

const Section = styled.section`
  & .custom-swiper-button-prev {
    content: url(${SwiperArrow});
    transform: rotate(180deg);
  }

  & .custom-swiper-button-next {
    content: url(${SwiperArrow});
  }

  & .swiper-button-disabled {
    opacity: 0.25;
  }
`

const ImageWrapper = styled(motion.div)`
  width: 100%;
  @media (min-width: 576px) {
    height: 450px;
  }
  @media (max-width: 575px) {
    height: 250px;
  }
`

const Image = styled(Plaatjie)`
  position: relative;
  height: 100%;
`

const GallerySlider: React.FC<GalleryProps> = ({ fields }) => {
  SwiperCore.use([Navigation])

  const [currentIndex, setCurrentIndex] = useState<number | null>(null)

  const { openLightbox } = useLightbox()

  const elements = fields.gallery?.map((image: any) => ({
    src: image?.localFile?.childImageSharp.gatsbyImageData.images.fallback.src,
  }))

  return (
    <Section className="pb-lg-5">
      <div className="container">
        <SimpleReactLightbox>
          <SRLWrapper elements={elements}>
            <Swiper
              navigation={{
                prevEl: '.custom-swiper-button-prev',
                nextEl: '.custom-swiper-button-next',
              }}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
              }}
              spaceBetween={20}
            >
              {fields.gallery?.map((image, index) => {
                const isOpen = currentIndex === index

                return (
                  <SwiperSlide key={index}>
                    <ImageWrapper
                      className="overflow-hidden"
                      role="button"
                      onClick={() => openLightbox(index)}
                      onHoverStart={() => setCurrentIndex(index)}
                      onHoverEnd={() => setCurrentIndex(null)}
                    >
                      <motion.div
                        initial={{ scale: 1 }}
                        animate={isOpen ? { scale: 1.1 } : { scale: 1.0 }}
                        exit={{ scale: 1 }}
                        transition={{ duration: 0.25 }}
                        className="h-100"
                      >
                        <Image image={image} alt="" className="h-100" />
                      </motion.div>
                    </ImageWrapper>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </SRLWrapper>
        </SimpleReactLightbox>
        <div className="d-flex flex-column align-items-center my-5">
          <div className="d-flex">
            <div className="custom-swiper-button-prev me-3" role="button">
              Vorige
            </div>
            <div className="custom-swiper-button-next" role="button">
              Volgende
            </div>
          </div>
          <div className="mt-4 pt-2">
            <ButtonPrimary to={fields.link?.url || '#'}>
              {fields.link?.title}
            </ButtonPrimary>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default GallerySlider
